.auto_content[data-v-de671e28] {
  margin: 0 0 5px;
}
.content_select[data-v-de671e28] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.content_select .left_select[data-v-de671e28] {
  display: flex;
  align-items: center;
}
.item_icon[data-v-de671e28] {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  color: #333;
}
.item_icon i[data-v-de671e28] {
  font-size: 13px;
  margin-right: 3px;
}
.content[data-v-de671e28] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
}
.content tr[data-v-de671e28] {
  padding: 0;
  margin: 0;
}
.content td[data-v-de671e28] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
  text-align: center;
}
.icon-weixintuisong[data-v-de671e28] {
  color: #15ba11;
  font-size: 13px;
  margin-left: 3px;
  cursor: pointer;
}